import * as React from 'react';
import { LoadRing } from 'Client/components/molecules';
import { Wrapper, LoadWrapper } from './LoadingDisplay.styles';

export const LoadingDisplay: React.FC = () => {
  return (
    <Wrapper>
      <LoadWrapper>
        <LoadRing loadingMessage={'Loading'} />
      </LoadWrapper>
    </Wrapper>
  );
};
