import * as React from 'react';
import copy from 'copy-to-clipboard';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { LoadRing, Switch } from 'Client/components/molecules';
import { Alert } from 'Atoms';
import {
  ChartItem,
  ContributionsFilters,
  PivotCriteria,
} from 'Shared/types/dashboard';
import { CopySmallIcon } from 'Atoms/Icons/Copy/CopySmallIcon';
import { useProject } from 'Client/utils/hooks';
import {
  ChartsAdditionalOptions,
  ChartsProps,
} from 'Client/pages/embedChart/types/types';
import { sortChartsProps } from 'Client/utils/embedCharts/sortChartProps';
import {
  ButtonWrapper,
  Button,
  ChartTitleInput,
  ContentTitle,
  IframeModalWrapper,
  SwitchWrapper,
  Content,
  AlertTitle,
  Divider,
  ScrollDiv,
  ChartPreviewWrapper,
  LeftContent,
  RightContent,
  PreviewTitle,
  LoadingCentralizer,
} from './IframeModal.styles';
import { ChartTypes, EmbedChartVars } from '../../types';
import { GET_EMBED_CHART_LINK } from '../ChartWrapper/ChartWrapper.gql';
import { handleChartsVars } from '../../utils/handleChartsVars';
import { EmbedChartPreview } from '../EmbedChartPreview/EmbedChartPreview';

export const IframeModal: React.FC<{
  setIsIframeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isIframeOpen: boolean;
  data: ChartItem;
  chartTypes: ChartTypes;
  id: string;
  pivotCriteria: { [key: string]: PivotCriteria };
  appliedFilters: ContributionsFilters;
  projectId: string;
  projectName: string;
}> = ({
  setIsIframeOpen,
  isIframeOpen,
  data,
  chartTypes,
  id,
  pivotCriteria,
  appliedFilters,
  projectId,
  projectName,
}) => {
  const title = data.label;
  const project = useProject();
  const { t } = useTranslation();

  const [copyText, setCopyText] = React.useState<string>('Copy iframe');
  const [chartTitle, setChartTitle] = React.useState<string>(title);
  const [borderSwitch, setBorderSwitch] = React.useState<boolean>(true);
  const [titleSwitch, setTitleSwitch] = React.useState<boolean>(true);
  const [backgroundSwitch, setBackgroundSwitch] = React.useState<boolean>(true);
  const [logoSwitch, setLogoSwitch] = React.useState<boolean>(true);
  const [filtersSwitch, setFiltersSwitch] = React.useState<boolean>(true);
  const [applyFiltersSwitch, setApplyFiltersSwitch] =
    React.useState<boolean>(true);
  const [additionalOptions, setAdditionalOptions] =
    React.useState<ChartsAdditionalOptions>(null);
  const [chartsProps, setChartsProps] = React.useState<ChartsProps>(null);

  const {
    contributionsVars,
    respondentsVars,
    SCDVars,
    overviewOrVisitorsVars,
  } = handleChartsVars({
    data,
    pivotCriteria,
    appliedFilters,
    id,
    projectId,
    projectName,
  });

  const firstEmbedChartVars = () => {
    const generalVars = {
      chartType: chartTypes[id],
      questionType: data.type,
      questionTitle: data.name,
      additionalOptions: {
        border: false,
        title: '',
        background: false,
        logo: false,
        filters: true,
        width: 100,
      },
    };

    switch (true) {
      case data.type === 'overview':
        return {
          operation: 'GetProjectOverviewChart',
          ...generalVars,
          ...overviewOrVisitorsVars,
        };
      case data.type === 'visitors':
        return {
          operation: 'getVisitorsLineChartData',
          ...generalVars,
          ...overviewOrVisitorsVars,
        };
      case data.isSpecialCategory:
        return {
          operation: 'getPseudoDemographicsChartData',
          ...SCDVars.chartsDataInput,
          ...generalVars,
        };
      case data.isRespondentsData:
        return {
          operation: 'getGaudiRespondentChartData',
          ...respondentsVars.respondentChartsDataInput,
          ...generalVars,
        };
      case pivotCriteria[id]?.type === 'device':
        return {
          operation: 'getChartDeviceData',
          ...contributionsVars.chartsDataInput,
          ...generalVars,
        };
      case data.type === 'sentiment':
        return {
          operation: 'getSentimentChartData',
          ...contributionsVars.chartsDataInput,
          ...generalVars,
        };
      default:
        return {
          operation: 'getChartData',
          ...contributionsVars.chartsDataInput,
          ...generalVars,
        };
    }
  };
  const [embedChartVars, setEmbedChartVars] = React.useState<EmbedChartVars>(
    () => firstEmbedChartVars()
  );

  const [
    getEmbedChartLink,
    { data: embedChartLinkData, loading: embedChartLinkLoading },
  ] = useLazyQuery(GET_EMBED_CHART_LINK, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [embedChartLink, setEmbedChartLink] = React.useState<string>('');

  React.useEffect(() => {
    getEmbedChartLink({
      variables: { embedChartsDataInput: { ...embedChartVars } },
    });
  }, [embedChartVars, getEmbedChartLink]);

  React.useEffect(() => {
    if (embedChartLinkData) {
      const { generateEmbedChartsLink } = embedChartLinkData;

      const { iframe, preview } = generateEmbedChartsLink || {};
      if (iframe && preview) {
        const chartsPropsToSave = sortChartsProps(preview.chartData);
        setChartsProps(chartsPropsToSave);
        setAdditionalOptions(preview.additionalOptions);
        setEmbedChartLink(iframe);
      }
    }
  }, [embedChartLinkData, embedChartLinkLoading]);

  React.useEffect(() => {
    const newVars = firstEmbedChartVars();
    newVars.additionalOptions = {
      border: borderSwitch,
      title: titleSwitch ? chartTitle : '',
      background: backgroundSwitch,
      logo: logoSwitch,
      filters: filtersSwitch,
      width: 100,
    };

    if (!applyFiltersSwitch) {
      const filtersName = data.isRespondentsData ? 'filters' : 'appliedFilters';
      newVars[filtersName] = {};
    }

    setEmbedChartVars({ ...newVars });
  }, [
    borderSwitch,
    logoSwitch,
    backgroundSwitch,
    filtersSwitch,
    titleSwitch,
    applyFiltersSwitch,
    chartTitle,
  ]);

  return (
    <IframeModalWrapper
      title="Prepare your chart"
      onClose={() => setIsIframeOpen(false)}
      open={isIframeOpen}
    >
      <Divider />
      <ScrollDiv>
        <Content>
          <LeftContent>
            <Alert type="tip">
              <AlertTitle>{t('Preparing your chart')}</AlertTitle>
              <p>
                {t(
                  `Customise your chart information, click on the "Copy iframe" button then paste it in the the edit mode of a tile. `
                )}
                <a
                  href="https://help-centre.commonplace.is/en/articles/60-how-to-embed-charts"
                  target="_blank"
                  rel="noreferrer"
                  data-onboarding="dashboard-charts-embed-chart-read-more"
                >
                  {/* change here add link styles */}
                  {t(`Read more.`)}
                </a>
              </p>
            </Alert>
            <ContentTitle marginTop="big" marginBottom="big">
              {t('Advanced Options')}
            </ContentTitle>
            <SwitchWrapper>
              <Switch
                colorMapping="CommonplaceBrand"
                checked={titleSwitch}
                onChange={() => setTitleSwitch(!titleSwitch)}
                data-onboarding="dashboard-charts-embed-chart-chart-title-toggle"
              />
              <p>{t('Include chart title')}</p>
            </SwitchWrapper>
            {!!titleSwitch && (
              <>
                <ContentTitle>{t('Chart title')}</ContentTitle>
                <ChartTitleInput
                  type="text"
                  onChange={(e) => setChartTitle(e.target.value)}
                  value={chartTitle}
                  placeholder={title}
                  data-onboarding="dashboard-charts-embed-chart-chart-title-field"
                />
              </>
            )}
            <SwitchWrapper>
              <Switch
                colorMapping="CommonplaceBrand"
                checked={applyFiltersSwitch}
                onChange={() => setApplyFiltersSwitch(!applyFiltersSwitch)}
                data-onboarding="dashboard-charts-embed-chart-apply-filters-toggle"
              />
              <p>{t('Apply filters (if any selected)')}</p>
            </SwitchWrapper>

            <SwitchWrapper>
              <Switch
                colorMapping="CommonplaceBrand"
                checked={backgroundSwitch}
                onChange={() => setBackgroundSwitch(!backgroundSwitch)}
                data-onboarding="dashboard-charts-embed-chart-background-toggle"
              />
              <p>{t('Include background')}</p>
            </SwitchWrapper>

            <SwitchWrapper>
              <Switch
                colorMapping="CommonplaceBrand"
                checked={borderSwitch}
                onChange={() => setBorderSwitch(!borderSwitch)}
                data-onboarding="dashboard-charts-embed-chart-border-toggle"
              />
              <p>{t('Include border')}</p>
            </SwitchWrapper>

            <SwitchWrapper>
              <Switch
                colorMapping="CommonplaceBrand"
                checked={filtersSwitch}
                onChange={() => setFiltersSwitch(!filtersSwitch)}
                data-onboarding="dashboard-charts-embed-chart-filter-summary-toggle"
              />
              <p>{t('Include filter summary')}</p>
            </SwitchWrapper>

            <SwitchWrapper>
              <Switch
                colorMapping="CommonplaceBrand"
                checked={logoSwitch}
                onChange={() => setLogoSwitch(!logoSwitch)}
                data-onboarding="dashboard-charts-embed-chart-commonplace-logo-toggle"
              />
              <p>{t('Include Commonplace logo')}</p>
            </SwitchWrapper>
          </LeftContent>
          <RightContent>
            <PreviewTitle>{t('Preview')}</PreviewTitle>
            {(!chartsProps && !additionalOptions) || embedChartLinkLoading ? (
              <LoadingCentralizer>
                <LoadRing loadingMessage="Loading" />
              </LoadingCentralizer>
            ) : (
              <ChartPreviewWrapper>
                <EmbedChartPreview
                  project={project}
                  chartsProps={chartsProps}
                  additionalOptions={additionalOptions}
                />
              </ChartPreviewWrapper>
            )}
          </RightContent>
        </Content>

        <div>
          <ButtonWrapper>
            <Button
              loading={embedChartLinkLoading}
              data-onboarding="dashboard-charts-embed-chart-copy-iframe"
              data-charturl={embedChartLinkLoading}
              onClick={() => {
                copy(embedChartLink);
                setCopyText('Copied!');
                setTimeout(() => {
                  setCopyText('Copy iframe');
                }, 5000);
              }}
            >
              <CopySmallIcon />
              {embedChartLinkLoading ? t('Loading') : copyText}
            </Button>
            <Button
              invertedTheme
              loading={embedChartLinkLoading}
              href="/edit"
              target="_blank"
              rel="noreferrer"
              data-onboarding="dashboard-charts-embed-chart-go-to-edit-mode"
            >
              {t('Go to edit mode')}
            </Button>
          </ButtonWrapper>
        </div>
      </ScrollDiv>
    </IframeModalWrapper>
  );
};
