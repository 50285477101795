import { ChartItem } from 'Shared/types/dashboard';
import { mapChartsTypesOptions } from '.';

export const generateChartTypes = (
  data: Array<ChartItem>
): { [key: string]: string } => {
  const newChartTypes = {};

  data.map((d) => {
    const type = mapChartsTypesOptions(d.type);

    newChartTypes[d.id] = type?.defaultOption?.value;
  });

  return newChartTypes;
};
