import { questionsToChartsType } from '../constants';
import { ChartsTypesOptions } from '../types';

export const mapChartsTypesOptions = (
  questionType: string,
  canHaveMaps?: boolean
): ChartsTypesOptions => {
  const questionMapping = questionsToChartsType.find(
    (mapping) => mapping.questionType === questionType
  );

  if (!questionMapping) return;

  const { defaultChartType, possibleChartTypes } = questionMapping;
  const defaultOption = { value: defaultChartType, label: defaultChartType };
  const options = possibleChartTypes.map((chartsTypes) => ({
    value: chartsTypes,
    label: chartsTypes,
  }));

  const mapSort = canHaveMaps
    ? [...options, { value: 'map', label: 'map' }]
    : options;

  return {
    defaultOption,
    options: mapSort,
  };
};
