export const MAX_WIDTH = 2;
export const MIN_WIDTH = 1;

export const toggleChartWidth = (
  id: string,
  layout: Array<{ [key: string]: number | string }>,
  newWidth?: number
) => {
  const newLayout = layout.map((q) => {
    const newQ = { ...q };
    if (q.i === id) {
      switch (true) {
        case !!newWidth:
          newQ.w = newWidth;
          break;
        case q.w === MIN_WIDTH:
          newQ.w = MAX_WIDTH;
          break;
        case q.w === MAX_WIDTH:
          newQ.w = MIN_WIDTH;
          break;
        default:
          newQ.w = MIN_WIDTH;
      }
    }
    return newQ;
  });
  return newLayout;
};
