/**
 * The `addCommas` function takes a number or string as input and returns a formatted string with
 * commas separating every three digits.
 * @param {number | string} value - The `value` parameter can be either a number or a string.
 * @param {string} currency - The `currency` parameter is optional and can be a string.
 * @returns The function `addCommas` returns a string with commas added as thousands separators.
 */
export const addCommas = (
  value: number | string,
  currency?: string
): string => {
  /*
    this regex adds a comma after every three digits, but only if there are more digits after it.
  */
  const newValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (currency) return `${newValue} ${currency}`;
  return newValue;
};
