import * as React from 'react';

export const NoDataDisplayIcon = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    data-testid="NoDataDisplayIcon-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)" fill="#00A85A">
      <path d="M11 21H2V2h26v12h2V2c0-.53043-.2107-1.039142-.5858-1.414215S28.5304 0 28 0H2C1.46957 0 .960858.210712.585785.585785S0 1.46957 0 2v19c0 .5304.210712 1.0391.585785 1.4142S1.46957 23 2 23h9v-2Z" />
      <path d="M20 3H4c-.26522 0-.51957.10534-.70711.29288C3.10536 3.48041 3 3.73478 3 4v13c0 .2652.10536.5196.29289.7071C3.48043 17.8946 3.73478 18 4 18s.51957-.1054.70711-.2929C4.89464 17.5196 5 17.2652 5 17V5h15c.2652 0 .5196-.10537.7071-.29291C20.8946 4.51956 21 4.26522 21 4s-.1054-.51959-.2929-.70712C20.5196 3.10534 20.2652 3 20 3ZM25 3h-2c-.2652 0-.5196.10534-.7071.29288C22.1054 3.48041 22 3.73478 22 4c0 .26522.1054.51956.2929.70709C22.4804 4.89463 22.7348 5 23 5h2c.2652 0 .5196-.10537.7071-.29291C25.8946 4.51956 26 4.26522 26 4s-.1054-.51959-.2929-.70712C25.5196 3.10534 25.2652 3 25 3ZM11 24h-1v2H7c-.26522 0-.51957.1053-.70711.2929C6.10536 26.4804 6 26.7348 6 27c0 .2652.10536.5196.29289.7071C6.48043 27.8946 6.73478 28 7 28h6l-2-4ZM27.31 25.9c1.1075-1.4293 1.6981-3.1916 1.6756-4.9996-.0225-1.808-.6568-3.555-1.7996-4.9563-1.1427-1.4013-2.7264-2.3741-4.4929-2.7599-1.7665-.3858-3.6116-.1618-5.2344.6355-1.6229.7973-2.9278 2.1208-3.702 3.7548s-.9721 3.4821-.5613 5.243c.4108 1.7608 1.406 3.3306 2.8233 4.4533C17.436 28.3936 19.1919 29.0031 21 29c1.7699.0101 3.492-.5745 4.89-1.66l4.4 4.4c.1874.1862.4408.2908.705.2908.2642 0 .5176-.1046.705-.2908.1862-.1874.2908-.4408.2908-.705 0-.2642-.1046-.5177-.2908-.705l-4.39-4.43ZM21 27c-1.1867 0-2.3467-.3519-3.3334-1.0112-.9867-.6593-1.7558-1.5963-2.2099-2.6927-.4541-1.0964-.5729-2.3028-.3414-3.4666.2315-1.1639.8029-2.233 1.642-3.0722.8392-.8391 1.9083-1.4105 3.0721-1.642 1.1639-.2315 2.3703-.1127 3.4667.3414 1.0963.4541 2.0334 1.2232 2.6927 2.2099C26.6481 18.6533 27 19.8133 27 21c0 1.5913-.6322 3.1174-1.7574 4.2426C24.1174 26.3679 22.5913 27 21 27Z" />
      <path d="M17 22c.2652 0 .5196-.1054.7071-.2929S18 21.2652 18 21c0-.7956.3161-1.5587.8787-2.1213C19.4413 18.3161 20.2044 18 21 18c.2652 0 .5196-.1054.7071-.2929S22 17.2652 22 17c0-.2652-.1054-.5196-.2929-.7071C21.5196 16.1053 21.2652 16 21 16c-1.3261 0-2.5979.5268-3.5355 1.4645C16.5268 18.4022 16 19.6739 16 21c0 .2652.1054.5196.2929.7071S16.7348 22 17 22Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
