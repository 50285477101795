import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 2.5rem;
  color: ${({ theme }) => theme.colors.black[500]};
  position: relative;
  top: 1.6rem;
  h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  svg {
    transform: scale(1.5);
  }
`;
