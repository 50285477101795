import { Options, SeriesOptionsType } from 'highcharts';
import map from '@highcharts/map-collection/custom/world.geo.json';
import proj4 from 'proj4';
import { ChartDefinitionProps } from '../types';
import { createCSVName, renderTooltipFormatter } from './utils';

export const generateMapChartOptions = ({
  mapSeries,
  questionTitle,
  projectName,
}: ChartDefinitionProps): Options => {
  const typedSeries = mapSeries as SeriesOptionsType[];

  return {
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    chart: {
      map,
      proj4,
      height: `720px`,
      backgroundColor: '#FAFAFA',
    },
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    title: {
      text: '',
      align: 'left',
    },
    mapNavigation: {
      enabled: true,
    },
    tooltip: {
      shape: 'rect',
      useHTML: true,
      hideDelay: 1500,
      borderWidth: 0,
      backgroundColor: '#fff',
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        const seriesName =
          typedSeries.length > 1 ? this.series.name : this.point.name;

        return renderTooltipFormatter(
          this,
          seriesName,
          '',
          undefined,
          'map',
          projectName
        );
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      mappoint: {
        cluster: {
          enabled: true,
          allowOverlap: false,
          animation: {
            duration: 450,
          },
          layoutAlgorithm: {
            type: 'grid',
            gridSize: 70,
          },
          zones: [
            {
              from: 1,
              to: 4,
              marker: {
                radius: 13,
              },
            },
            {
              from: 5,
              to: 9,
              marker: {
                radius: 15,
              },
            },
            {
              from: 10,
              to: 15,
              marker: {
                radius: 17,
              },
            },
            {
              from: 16,
              to: 20,
              marker: {
                radius: 19,
              },
            },
            {
              from: 21,
              to: 100,
              marker: {
                radius: 21,
              },
            },
          ],
        },
      },
    },
    series: [
      {
        name: 'World',
        accessibility: {
          exposeAsGroupOnly: true,
        },
        borderColor: '#A0A0A0',
        nullColor: 'rgba(177, 244, 177, 0.5)',
        showInLegend: false,
        type: 'map',
      },
      ...typedSeries,
    ],
    exporting: {
      filename: createCSVName(questionTitle),
    },
  };
};
