import { captureException } from '@sentry/node';
import { questionsToChartsType } from '../constants';
import {
  generateColumnChartOptions,
  generatePieChartOptions,
  generateLineChartOptions,
  generateWordCloudChartOptions,
  generateBarChartOptions,
  generateVisitorsLineChartOptions,
  generatePackedBubbleChartOptions,
  generateMapChartOptions,
} from '../chartsDefinitions';
import { GetChartsDataTypeOptionsProps, ChartsGroupOfOptions } from '../types';

const getChartOptionsGenerator = (chartType: string, pivotCriteria: string) => {
  switch (true) {
    case chartType === 'column':
      return generateColumnChartOptions;
    case chartType === 'pie':
      return generatePieChartOptions;
    case chartType === 'line':
      return generateLineChartOptions;
    case chartType === 'wordcloud' && !pivotCriteria:
      return generateWordCloudChartOptions;
    case chartType === 'wordcloud' && !!pivotCriteria:
      return generatePackedBubbleChartOptions;
    case chartType === 'visitorsLine':
      return generateVisitorsLineChartOptions;
    case chartType === 'bar':
      return generateBarChartOptions;
    case chartType === 'map':
      return generateMapChartOptions;
    default:
      return generateColumnChartOptions;
  }
};

export const getChartsDataTypeOptions = ({
  dataType,
  series,
  wordcloudData,
  timeSeries,
  donutSeries,
  months,
  categories,
  pivotCriteria,
  questionTitle,
  mapSeries,
  enableMapChart,
  project,
}: GetChartsDataTypeOptionsProps): ChartsGroupOfOptions => {
  try {
    const questionDefinition = questionsToChartsType.find(
      (questionMapping) => questionMapping.questionType === dataType
    );

    const canHaveMaps = enableMapChart && project.features.mapChart;

    const { possibleChartTypes } = questionDefinition;

    const chartTypesWithMaps = canHaveMaps
      ? [...possibleChartTypes, 'map']
      : [...possibleChartTypes];

    const chartsOptionsForQuestion: ChartsGroupOfOptions =
      chartTypesWithMaps.reduce((accumulator, current) => {
        const getChartOptions = getChartOptionsGenerator(
          current,
          pivotCriteria
        );

        const chartOptions = getChartOptions({
          series,
          wordcloudData: wordcloudData || [],
          timeSeries: timeSeries || series,
          donutSeries,
          months,
          categories,
          pivotCriteria: pivotCriteria || '',
          questionTitle,
          mapSeries: mapSeries || [],
          projectName: project.id,
        });

        accumulator[current] = chartOptions;

        return accumulator;
      }, {});

    return chartsOptionsForQuestion;
  } catch (error) {
    captureException('Error @ getChartsDataTypeOptions.ts: ', error);
  }
};
