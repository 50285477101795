import { PivotCriteria } from 'Shared/types/dashboard';
import { GetNewPivotCriteriaProps } from '../types';

const specialPivot = ['device'];

export const getNewPivotCriteria = ({
  pivotData,
  pivotOptionValue,
}: GetNewPivotCriteriaProps): PivotCriteria => {
  if (!pivotOptionValue) return null;
  if (specialPivot.includes(pivotOptionValue)) {
    return {
      type: pivotOptionValue,
      category: pivotOptionValue,
      questionName: '',
    };
  }
  const selectedPivotData = pivotData.find(
    (data) => data.category === pivotOptionValue
  );
  const newPivotCriteria = {
    type: selectedPivotData.type,
    category: selectedPivotData.category,
    questionName: selectedPivotData.name,
  };
  return newPivotCriteria;
};
