import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import HighchartsData from 'highcharts/modules/data';
import HC_more from 'highcharts/highcharts-more';
import wordCloud from 'highcharts/modules/wordcloud';
import mapCharts from 'highcharts/modules/map';
import accessibility from 'highcharts/modules/accessibility';
import mapCluster from 'highcharts/modules/marker-clusters';
import Boost from 'highcharts/modules/boost';
import { v4 as uuid } from 'uuid';
import { useProject } from 'Client/utils/hooks';
import { NavigationalMap } from 'Client/components/organisms';
import { Wrapper } from './ChartRenderer.styles';
import { ChartRendererProps, ChartsGroupOfOptions } from '../../types';
import { getChartsDataTypeOptions } from '../../utils';

export const ChartRenderer: React.FC<ChartRendererProps> = ({
  series,
  categories,
  dataType,
  chartType,
  timeSeries,
  mapSeries,
  wordcloudData,
  donutSeries,
  months,
  pivotCriteria,
  questionTitle,
  questionId,
  setSettingsFunctions,
  enableMapChart,
  mapSlug,
  contributionsIds,
}: ChartRendererProps) => {
  if (typeof Highcharts === 'object') {
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    offlineExporting(Highcharts);
    wordCloud(Highcharts);
    mapCharts(Highcharts);
    mapCluster(Highcharts);
    accessibility(Highcharts);
    HighchartsData(Highcharts);
    HC_more(Highcharts);
    Boost(Highcharts);
  }

  const project = useProject();
  const [chartsOptionsForType, setChartsOptionsForType] =
    React.useState<ChartsGroupOfOptions>(null);

  const chart = React.useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);

  React.useEffect(() => {
    const chartsOptionsForType = getChartsDataTypeOptions({
      categories,
      series,
      wordcloudData,
      timeSeries,
      donutSeries,
      months,
      dataType,
      pivotCriteria,
      questionTitle,
      mapSeries,
      enableMapChart,
      project,
    });
    setChartsOptionsForType(chartsOptionsForType);
  }, [
    categories,
    dataType,
    donutSeries,
    mapSeries,
    enableMapChart,
    months,
    pivotCriteria,
    project,
    questionTitle,
    series,
    timeSeries,
    wordcloudData,
  ]);

  const printChart = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.print();
    }
  };

  const downloadCSV = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadXLS = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadXLS();
    }
  };

  const exportChart = (type) => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.exportChart(
        {
          type: type,
        },
        {}
      );
    }
  };

  const viewInFullScreen = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.fullscreen.toggle();
    }
  };

  React.useEffect(() => {
    setSettingsFunctions((previous) => {
      return {
        viewInFullScreen,
        printChart,
        downloadPNG: () => exportChart('image/png'),
        downloadJPEG: () => exportChart('image/jpeg'),
        downloadPDF: () => exportChart('application/pdf'),
        downloadSVG: () => exportChart('image/svg+xml'),
        downloadCSV,
        downloadXLS,
        generateIframe: previous.generateIframe,
      };
    });
  }, [setSettingsFunctions]);

  return (
    <>
      <Wrapper id="container">
        {Object.keys(chartsOptionsForType || {}).map((option, index) => {
          if (option === chartType && chartType === 'map') {
            return (
              <div key={`${dataType}-${mapSlug}-${index}`}>
                <NavigationalMap
                  content={{
                    slug: mapSlug,
                  }}
                  header={false}
                  index={`${mapSlug}-${uuid()}`}
                  filter={{ contributionsIds }}
                  isMapChart={true}
                  questionInfo={{ questionId, questionName: questionTitle }}
                />
              </div>
            );
          }

          if (option === chartType && chartType !== 'map') {
            return (
              <HighchartsReact
                key={`${dataType}-${questionTitle}-${chartType}-${option}-${index}`}
                highcharts={Highcharts}
                constructorType={'chart'}
                options={chartsOptionsForType[option]}
                allowChartUpdate={true}
                immutable={true}
                ref={chart}
              />
            );
          }
          return null;
        })}
      </Wrapper>
    </>
  );
};
