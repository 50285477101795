import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 28rem;
`;

export const Message = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;
