import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-width: 35rem;
  min-height: 31rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    177.36deg,
    #eeeeee 2.2%,
    rgba(247, 247, 247, 0) 147.68%
  );
  border-radius: 0.3125rem;
`;

export const LoadWrapper = styled.div`
  filter: grayscale(1) brightness(0.7);
`;
