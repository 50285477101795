import {
  ChartItem,
  ContributionsFilters,
  PivotCriteria,
} from 'Shared/types/dashboard';

export const handleChartsVars = ({
  projectId,
  projectName,
  id,
  data,
  appliedFilters,
  pivotCriteria,
}: {
  projectId: string;
  projectName: string;
  id: string;
  data: ChartItem;
  appliedFilters: ContributionsFilters;
  pivotCriteria: { [key: string]: PivotCriteria };
}) => {
  const contributionsVars = {
    chartsDataInput: {
      projectId: projectId,
      questionId: id,
      projectName: projectName,
      questionName: data.name,
      appliedFilters: appliedFilters,
      language: 'en-GB',
      pivotCriteria: pivotCriteria[id],
    },
  };

  const respondentsVars = {
    respondentChartsDataInput: {
      language: 'en-GB',
      filters: appliedFilters,
      questionName: data.name,
      projectId: projectId,
      projectName: projectName,
      pivotCriteria: pivotCriteria[id],
    },
  };

  const SCDVars = {
    chartsDataInput: {
      appliedFilters,
      projectName,
      projectId,
      questionName: data.name,
    },
  };

  const overviewOrVisitorsVars = {
    projectName,
    projectId,
    date: appliedFilters?.topFilters?.date,
  };

  return {
    contributionsVars,
    respondentsVars,
    SCDVars,
    overviewOrVisitorsVars,
  };
};
