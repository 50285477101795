import { gql } from '@apollo/client';

export const GET_CHART_QUERY = gql`
  query ChartData($chartsDataInput: ChartsDataParams) {
    getChartData(ChartsDataInput: $chartsDataInput) {
      chartData {
        ... on ChartData {
          categories
          data
          drillDownSeries {
            categories
            pieSeries {
              drilldown {
                categories
                data
                name
              }
              y
            }
          }
          mapSeries {
            data {
              lat
              lon
              name
              contributionId
            }
            enabledMouseTracking
            marker {
              fillColor
            }
            name
            type
          }
          months
          pivotCriteria {
            category
            isSpecialCategory
            questionName
            type
          }
          questionWithContent {
            _id
            content
            questionContent
          }
          series {
            color
            data
            name
            stack
            type
          }
          wordSeries {
            data {
              name
              value
            }
            name
          }
          timeSeries {
            color
            data
            name
            stack
          }
          enableMapChart
        }
      }
      isValidData
      status
    }
  }
`;
export const GET_SENTIMENT_CHART_QUERY = gql`
  query GetSentimentChartData($chartsDataInput: ChartsDataParams) {
    getSentimentChartData(ChartsDataInput: $chartsDataInput) {
      chartData {
        ... on ChartData {
          categories
          months
          data
          wordSeries {
            name
            data {
              name
              value
            }
          }
          mapSeries {
            data {
              lat
              lon
              name
              contributionId
            }
            enabledMouseTracking
            marker {
              fillColor
            }
            name
            type
          }
          series {
            name
            data
            stack
            type
            color
          }
          timeSeries {
            name
            data
            stack
            color
          }
          drillDownSeries {
            pieSeries {
              y
              drilldown {
                name
                categories
                data
              }
            }
            categories
          }
          pivotCriteria {
            type
            questionName
            category
            isSpecialCategory
          }
          questionWithContent {
            _id
            content
            questionContent
          }
        }
      }
    }
  }
`;
export const GET_DEVICE_CHART_QUERY = gql`
  query GetChartDeviceData($chartsDataInput: ChartsDataParams) {
    getChartDeviceData(ChartsDataInput: $chartsDataInput) {
      chartData {
        ... on ChartData {
          categories
          series {
            data
            name
            stack
            type
            color
          }
          timeSeries {
            data
            name
            stack
            color
          }
          drillDownSeries {
            categories
            pieSeries {
              drilldown {
                categories
                data
                name
              }
              y
            }
          }
          months
          pivotCriteria {
            type
            category
            questionName
            isSpecialCategory
          }
        }
      }
    }
  }
`;
export const GET_RESPONDENTS_CHART_QUERY = gql`
  query GetGaudiRespondentsChartData(
    $respondentChartsDataInput: RespondentChartDataParams
  ) {
    getGaudiRespondentChartData(
      RespondentChartsDataInput: $respondentChartsDataInput
    ) {
      isValidData
      status
      chartData {
        ... on ChartData {
          categories
          months
          data
          wordSeries {
            name
            data {
              name
              value
            }
          }
          series {
            name
            data
            stack
            type
            color
          }
          data
          timeSeries {
            name
            data
            stack
            color
          }
          drillDownSeries {
            pieSeries {
              drilldown {
                name
                categories
                data
              }
              y
            }
            categories
          }
          pivotCriteria {
            type
            category
            questionName
            isSpecialCategory
          }
          questionWithContent {
            _id
            content
            questionContent
          }
        }
      }
    }
  }
`;
export const GET_SCD_CHART_QUERY = gql`
  query GetPseudoDemographicsChartData(
    $chartsDataInput: SpecialCategoryDataChartParams
  ) {
    getPseudoDemographicsChartData(ChartsDataInput: $chartsDataInput) {
      isValidData
      status
      chartData {
        ... on ChartData {
          categories
          months
          data
          wordSeries {
            name
            data {
              name
              value
            }
          }
          series {
            name
            data
            stack
            type
            color
          }
          data
          timeSeries {
            name
            data
            stack
            color
          }
          drillDownSeries {
            pieSeries {
              drilldown {
                name
                categories
                data
              }
              y
            }
            categories
          }
          pivotCriteria {
            type
            category
            questionName
            isSpecialCategory
          }
          questionWithContent {
            _id
            content
            questionContent
          }
        }
      }
    }
  }
`;

export const GET_EMBED_CHART_LINK = gql`
  query Query($embedChartsDataInput: JSON) {
    generateEmbedChartsLink(EmbedChartsDataInput: $embedChartsDataInput) {
      preview {
        isValidData
        status
        chartData {
          ... on LineChartData {
            lineCategories: categories
            lineSeries: series {
              name
              data {
                y
                name
                marker {
                  enabled
                }
              }
            }
          }
          ... on ChartData {
            categories
            months
            series {
              name
              data
              stack
              type
              color
            }
            wordSeries {
              name
              data {
                name
                value
              }
            }
            data
            timeSeries {
              name
              data
              stack
              color
            }
            drillDownSeries {
              pieSeries {
                y
                drilldown {
                  name
                  categories
                  data
                }
              }
              categories
            }
            pivotCriteria {
              type
              category
              questionName
              isSpecialCategory
            }
            questionWithContent {
              _id
              content
              questionContent
            }
            mapSeries {
              type
              enabledMouseTracking
              marker {
                fillColor
              }
              name
              data {
                name
                lat
                lon
                contributionId
              }
            }
            questionType
            chartType
            questionTitle
            operation
          }
        }
        additionalOptions {
          border
          background
          filters
          logo
          title
        }
      }
      iframe
    }
  }
`;
