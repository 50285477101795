import styled from 'styled-components';
import { opacify } from 'polished';
import { Modal as IframeModalBase } from 'Components/molecules/Modal/Modal';

export const IframeModalWrapper = styled(IframeModalBase)`
  height: 80vh;
  max-height: 56.875rem;
  top: 10%;
  left: 8.5%;
  width: 85vw;
  padding: 3.75rem 0 1rem;
  transform: none;
  padding-bottom: 1.0625rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  height: 85%;
  overflow-y: auto;
  padding: 0 2rem;

  div:first-child {
    text-align: left;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    display: flex;
    align-items: center;
  }
`;

export const LoadingCentralizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Button = styled.a<{
  loading?: boolean;
  invertedTheme?: boolean;
}>`
  padding: 0.625rem;
  height: 2.5rem;
  width: 9.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, invertedTheme }) =>
    invertedTheme
      ? theme.colors.white[500]
      : theme.colorMappings.CommonplaceBrand};
  cursor: ${({ loading }) => (loading ? 'wait' : 'pointer')};
  color: ${({ theme, invertedTheme }) =>
    invertedTheme
      ? theme.colorMappings.CommonplaceBrand
      : theme.colors.white[500]};
  border: 0.0625rem solid
    ${({ theme, invertedTheme }) =>
      invertedTheme && theme.colorMappings.CommonplaceBrand};
  border-radius: 0.25rem;
  font-weight: 600;
  margin-right: 1.5625rem;

  svg {
    margin-right: 0.25rem;
  }

  :hover {
    background: ${({ theme, invertedTheme }) =>
      invertedTheme ? theme.colors.white[600] : theme.colors.green[600]};
  }
`;

export const ContentTitle = styled.div<{
  marginTop?: 'big';
  marginBottom?: 'big';
}>`
  font-weight: 700;
  font-size: 1.125rem;
  margin: ${({ marginTop }) => (marginTop === 'big' ? '1rem' : '0')} 0
    ${({ marginBottom }) => (marginBottom === 'big' ? '0.5rem' : '0')} 0.375rem;
`;
export const ChartTitleInput = styled.input`
  border: 0.0625rem solid ${({ theme }) => theme.colors.purple[300]};
  border-radius: 0.5rem;
  margin: 0.375rem 0 1rem 0.375rem !important;
  font-weight: 400;
  padding: 0.9375rem;
  width: 98%;
  text-align: initial;
  font-size: 1rem !important;

  :focus-visible {
    outline: 0.25rem solid
      ${({ theme }) => opacify(-0.8, theme.colors.purple[300])};
  }
`;
export const ToogleWidth = styled.div`
  display: flex;
  border: 0.1875rem solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.grey[300]};
  width: 33%;
  justify-content: space-evenly;
  margin-left: 0.375rem;
`;
export const WidthButton = styled.div<{ active: boolean }>`
  margin: 0;
  width: 50%;
  display: flex;
  align-items: center;
  height: 2rem;
  justify-content: center;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  border-radius: 0.125rem;
  background-color: ${({ active, theme }) =>
    active ? theme.colorMappings.CommonplaceBrand : ''};
  color: ${({ active, theme }) => (active ? theme.colors.white[500] : 'black')};
  transition: ${({ active }) =>
    active
      ? 'color 0.3s, background-color 1.3s'
      : 'color 0.3s, background-color 0.5s'};

  p {
    margin: 0;
    padding: 0;
  }
`;
export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  label {
    margin-right: 0;
  }

  p {
    font-weight: 600;
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }
`;

export const Separator = styled.hr`
  border: none;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey[100]};
  width: 98%;
  margin-left: 0.375rem;
`;

export const Divider = styled.span`
  display: block;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.grey[100]};
`;

export const ScrollDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AlertTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  text-align: left;
`;

export const PreviewTitle = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  margin-left: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  text-align: left;
`;

export const ChartPreviewWrapper = styled.div`
  box-shadow: 0 0.0625rem 0.875rem 0 ${({ theme }) => theme.colors.grey[500]};
  height: 84%;
  margin: 1rem 1rem 1rem 2rem;
`;

export const LeftContent = styled.div`
  width: 60%;

  a {
    color: blue;
    text-decoration: underline;
  }
`;
export const RightContent = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
