import {
  mapDonutSeries,
  mapPivotedData,
  mapSeriesDataToY,
} from 'Client/pages/dashboard/charts/utils';

export const sortChartsProps = (chartData) => {
  const {
    series,
    lineSeries,
    lineCategories,
    timeSeries,
    data: wordcloudData,
    wordSeries: pivotWordSeries,
    pivotCriteria: isPivoted,
    categories,
    months,
    drillDownSeries,
    mapSeries,
    questionType,
    chartType,
    questionTitle,
  } = chartData;

  const props = {
    series: series || [],
    wordcloudData: wordcloudData || [],
    timeSeries: timeSeries || [],
    donutSeries: [],
    mapSeries: mapSeries || [],
    months: months || [],
    categories: categories || [],
    chartType: chartType || '',
    dataType: questionType || '',
    questionTitle: questionTitle || '',
    pivotCriteria: isPivoted || null,
  };

  if (isPivoted && !pivotWordSeries) {
    const mappedSeries = mapPivotedData({ series, categories });
    const donutSeries = mapDonutSeries(
      drillDownSeries?.pieSeries,
      isPivoted.category,
      questionType
    );

    return {
      ...props,
      categories,
      series: mappedSeries,
      donutSeries,
      wordcloudData: pivotWordSeries,
    };
  }

  if (pivotWordSeries && pivotWordSeries[0]?.data !== null) {
    const pivotedWordcloudData = JSON.parse(JSON.stringify(pivotWordSeries));
    props.wordcloudData = pivotedWordcloudData;
    return {
      ...props,
      wordcloudData: pivotedWordcloudData,
    };
  }

  if (chartType === 'bar') {
    return {
      ...props,
      categories,
      series: series,
    };
  }

  if (chartType === 'map') {
    return { ...props, mapSeries: mapSeries };
  }

  if (lineSeries && lineCategories) {
    return {
      ...props,
      dataType: 'overview',
      chartType: 'visitorsLine',
      series: lineSeries,
      categories: lineCategories,
    };
  }

  const mappedSeries = mapSeriesDataToY(series || []);
  return {
    ...props,
    series: mappedSeries,
    categories,
    timeSeries,
    months,
    wordcloudData,
  };
};
