import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    margin: 0;
    display: inline;
  }
`;

export const EmbedChartWrapper = styled.div<{
  border: boolean;
}>`
  border: ${({ theme, border }) =>
    border ? `0.125rem solid ${theme.colors.grey['A200']}` : ''};
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.8125rem 0.25rem;
`;
export const FilterSummary = styled.div`

& > span {
  font-weight: 700;
  margin: 0;
  padding 0;
}

& > ul {
  margin: 0;
}
`;

export const Modal = styled.div`
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
  text-wrap: wrap;
`;
