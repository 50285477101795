import { MapPivotedDataProps, SeriesType } from '../types';

export const mapPivotedData = ({
  series,
  categories,
}: MapPivotedDataProps): SeriesType => {
  try {
    const stackOptions = Array.from(new Set(series.map((data) => data.stack)));
    const mappedSeries = stackOptions.map((stack) => {
      const newData = categories.map((category) => {
        const seriesElement = series.find(
          (element) => element.stack === stack && element.name == category
        );
        return seriesElement.data[0];
      });
      return {
        name: stack,
        type: 'column',
        data: newData as Array<number>,
        stacking: 'normal',
      };
    });
    return mappedSeries as SeriesType;
  } catch (error) {
    console.log('error', error);
    return [];
  }
};
