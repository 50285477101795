import * as React from 'react';
import { Fullscreen, PrintIcon } from 'Components/atoms/Icons';
import { useProject } from 'Client/utils/hooks';
import { SettingsMenuProps } from './types';
import {
  ButtonsContainer,
  Wrapper,
  Button,
  StyledDownloadIcon,
  NewIcon,
} from './SettingsMenu.styles';

export const SettingsMenu: React.FC<SettingsMenuProps> = ({
  settingsFunctions,
}: SettingsMenuProps) => {
  const project = useProject();

  return (
    <Wrapper>
      {/* <ButtonsContainer>
        <Button onClick={() => ''}>
          <DownloadIcon></DownloadIcon>
          Add to news
        </Button>
        <Button onClick={() => ''}>
          <DownloadIcon></DownloadIcon>
          Add to report
        </Button>
      </ButtonsContainer> */}
      <ButtonsContainer>
        <Button
          onClick={settingsFunctions.viewInFullScreen}
          data-onboarding="dashboard-charts-settings-show-in-full-screen"
        >
          <Fullscreen />
          View in full screen
        </Button>
        <Button
          onClick={settingsFunctions.printChart}
          data-onboarding="dashboard-charts-settings-print-chart"
        >
          <PrintIcon />
          Print chart
        </Button>
      </ButtonsContainer>
      <ButtonsContainer>
        {project?.features?.embedCharts && (
          <Button
            onClick={settingsFunctions.generateIframe}
            data-onboarding="dashboard-charts-settings-embed-chart"
          >
            <NewIcon>NEW</NewIcon>
            Embed chart
          </Button>
        )}
      </ButtonsContainer>
      <ButtonsContainer lastContainer>
        <Button
          onClick={settingsFunctions.downloadPNG}
          data-onboarding="dashboard-charts-settings-download-png"
        >
          <StyledDownloadIcon />
          Download PNG
        </Button>
        <Button
          onClick={settingsFunctions.downloadJPEG}
          data-onboarding="dashboard-charts-settings-download-jpeg"
        >
          <StyledDownloadIcon />
          Download JPEG
        </Button>
        <Button
          onClick={settingsFunctions.downloadSVG}
          data-onboarding="dashboard-charts-settings-download-svg"
        >
          <StyledDownloadIcon />
          Download SVG
        </Button>
        <Button
          onClick={settingsFunctions.downloadPDF}
          data-onboarding="dashboard-charts-settings-download-pdf"
        >
          <StyledDownloadIcon />
          Download PDF
        </Button>
        <Button
          onClick={settingsFunctions.downloadCSV}
          data-onboarding="dashboard-charts-settings-download-csv"
        >
          <StyledDownloadIcon />
          Download CSV
        </Button>
      </ButtonsContainer>
    </Wrapper>
  );
};
