import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Message } from './NoDataDisplay.styles';
import { NoDataDisplayIcon } from './NoDataDisplayIcon';

export const NoDataDisplay: React.FC = () => {
  const { t } = useTranslation('customer');
  return (
    <Wrapper>
      <NoDataDisplayIcon />
      <Message data-testid={'NoData-Message'}>{t('No data available')}</Message>
    </Wrapper>
  );
};
