import { captureException } from '@sentry/react';
import { getDonutInnerSeriesNames } from '.';
import { rawDonutSeries } from '../types';

export const mapDonutSeries = (
  drillDownSeries: rawDonutSeries,
  pivotCriteria: string,
  questionType: string
) => {
  try {
    const innerChartSeriesData = drillDownSeries.map((series) => {
      return { name: series.drilldown.name, y: series.y };
    });
    const outerChartSeriesData = drillDownSeries
      .map((series) => {
        const { categories, data } = series.drilldown;
        const seriesData = categories.map((category, index) => {
          return { name: category, y: data[index] };
        });
        return seriesData;
      })
      .flat();
    const series = [
      {
        name: getDonutInnerSeriesNames(questionType),
        data: innerChartSeriesData,
        size: '60%',
      },
      {
        name: pivotCriteria,
        data: outerChartSeriesData,
        size: '80%',
        innerSize: '60%',
      },
    ];
    return series;
  } catch (error) {
    captureException(`Error @ mapDonutSeries in mapDonutSeries.ts ${error}`);
  }
};
