import * as React from 'react';
import { TooltipFormatterContextObject } from 'highcharts';
import { renderToString } from 'react-dom/server';
import { makeRedirectDomain } from 'Client/utils/url';
import { ChartTooltip } from '../../components';

const getPackedBubbleTotal = (data) => {
  let acc = 0;
  data.map((obj) => {
    acc += obj.value;
  });

  return parseFloat(acc.toFixed(2));
};

const getVisitorsPercentage = (
  totalData: Array<number>,
  pointValue: number
) => {
  const total = totalData.reduce((c, acc) => c + acc);
  return (pointValue / total) * 100;
};

export const renderTooltipFormatter = (
  formatter: TooltipFormatterContextObject,
  name: string | number,
  pivotCriteria: string,
  weight?: number,
  type?: string,
  projectName?: string
): string => {
  //highcharts types are weirdly missing props that the objects do have
  const { yData } = formatter.point.series as unknown as {
    yData: Array<number>;
  };
  const date = formatter.key as unknown as string;
  const percentage = (() =>
    (!!formatter.percentage && formatter.percentage) ||
    (type === 'line' && getVisitorsPercentage(yData, formatter.y)))();

  const count = (() => {
    if (type === 'map') return;
    if (weight) return weight;
    if (formatter.y >= 0) return formatter.y;
    return getPackedBubbleTotal(formatter.series.data);
  })();

  const color = (() => {
    if (type === 'map') {
      return (formatter.point as unknown as { graphic: { fillColor: string } })
        .graphic.fillColor;
    }
    if (typeof formatter.color === 'string') {
      return formatter.color;
    }

    return '#fff';
  })();

  const currency = (() => {
    if (type === 'map') return;

    return (
      (
        formatter.series.data[0] as unknown as {
          currency: string;
        }
      )?.currency || null
    );
  })();

  const mapPosition = (() => {
    const { lat, lon, clusteredData } = formatter.point as unknown as {
      lat: number;
      lon: number;
      clusteredData: Array<unknown>;
    };
    if (type === 'map' && lat && lon) {
      return `lat: ${parseFloat(lat.toFixed(3))}, lon: ${parseFloat(
        lon.toFixed(3)
      )}`;
    }

    if (type === 'map' && clusteredData.length > 0) {
      return `Count: ${clusteredData.length}`;
    }

    return;
  })();

  const contributionId = (
    formatter?.point as unknown as { contributionId: string }
  )?.contributionId;
  const contributionLink =
    contributionId &&
    `http://${makeRedirectDomain(
      projectName
    )}/v5/dashboard/contributions/${contributionId}`;

  return renderToString(
    <ChartTooltip
      name={name || pivotCriteria}
      type={type ? type : null}
      date={date}
      count={count}
      currency={currency}
      color={color}
      percentage={percentage}
      pivot={pivotCriteria}
      mapPosition={mapPosition}
      contributionLink={contributionLink}
    />
  );
};
