import * as React from 'react';
import { ChartTooltipProps } from './types';
import { addCommas } from '../../utils/addCommas';

const filterPercentage = (percentage: number) => {
  return parseFloat(percentage.toFixed(2));
};

const filterPivot = (pivot: string) => {
  const array = pivot.split('');
  array[0] = array[0].toUpperCase();
  return array.join('');
};

/// Had to add inline styles due to the way this component is being rendered inside charts definitions.
/// Via renderToString(), styled-components doesn't work
export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  name,
  pivot,
  count,
  percentage,
  color,
  type,
  date,
  mapPosition,
  contributionLink,
  currency,
}: ChartTooltipProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        maxHeight: percentage ? '76px' : '54px',
        paddingRight: '1rem',
        fontFamily: 'Source Sans Pro',
        color: '#585858',
        background: '#fff',
      }}
    >
      <div
        color={color}
        style={{
          width: '0.25rem',
          height: percentage ? '4.75rem' : '3.375rem',
          background: color,
          borderRadius: '6.25rem',
          margin: '0rem 0.875rem 0 0.25rem',
        }}
      />
      <div
        style={{
          padding: '0.625rem 0 0.625rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '4.75rem',
            marginBottom: '0.375rem',
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: '0.6875rem',
              lineHeight: '0.875rem',
              color: 'black',
            }}
          >
            {name} {type === 'line' ? `- ${date}` : ''}
          </span>
          {!!pivot && (
            <>
              <svg
                width="3"
                height="4"
                fill="none"
                style={{
                  margin: '0 0.25rem',
                }}
              >
                <circle cx="1.5" cy="2" r="1.5" fill="black" />
              </svg>
              <span
                style={{
                  fontSize: '0.6875rem',
                }}
              >
                {filterPivot(pivot)}
              </span>
            </>
          )}
        </div>
        {!!count && (
          <span
            style={{
              fontSize: '0.6875rem',
              marginBottom: percentage ? '0.375rem' : '',
            }}
          >
            Count: {addCommas(count, currency)}
          </span>
        )}
        {!!percentage && (
          <span
            style={{
              fontSize: '0.6875rem',
            }}
          >
            Perc. {pivot || type === 'line' ? 'series' : 'total'}:{' '}
            {filterPercentage(percentage)}%
          </span>
        )}
        {!!mapPosition && (
          <span
            style={{
              fontSize: '0.6875rem',
            }}
          >
            {mapPosition}
          </span>
        )}
        {!!contributionLink && (
          <a
            style={{
              fontSize: '0.75rem',
              color: '#00AC5D',
            }}
            href={contributionLink}
            target="_blank"
            rel="noreferrer"
          >
            See contribution
          </a>
        )}
      </div>
    </div>
  );
};
