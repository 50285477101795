import { GroupedOptions, OptionItem } from 'Client/types';
import { PivotData } from '../types';

export const mapDemographicsOptions = (
  demographicsData: Array<PivotData>
): Array<GroupedOptions> => {
  const defaultOptionsObject = [
    {
      label: 'Demographics',
      options: [] as Array<OptionItem>,
    },
    {
      label: 'Special category demographics',
      options: [] as Array<OptionItem>,
    },
  ];
  const options = demographicsData.reduce((accumulator, pivotData) => {
    const accumulatorIndex = accumulator.findIndex((optionItem) => {
      if (pivotData.isSpecialCategory) {
        return optionItem.label === 'Special category demographics';
      }
      return optionItem.label === 'Demographics';
    });
    const { options } = accumulator[accumulatorIndex];
    const newOption = {
      label: pivotData.category,
      value: pivotData.category,
    };
    const newOptions = [...options, newOption] as Array<OptionItem>;
    accumulator[accumulatorIndex].options = newOptions;
    return accumulator;
  }, defaultOptionsObject);
  //adds device pivot option to Demographics - to be added to own section later
  options[0].options.push({ label: 'Device', value: 'device' });
  return options;
};
