import { Options, SeriesOptionsType, XAxisOptions } from 'highcharts';
import { theme } from 'Client/components/theme';
import { ChartDefinitionProps } from '../types';
import { sharedOptions } from '.';
import { createCSVName, renderTooltipFormatter } from './utils';

const getAdditionalXAxisConfig = (categories: Array<string>): XAxisOptions => {
  if (categories?.length) {
    return { categories };
  }
  return { type: 'category' };
};

export const generateColumnChartOptions = ({
  series,
  categories,
  pivotCriteria,
  questionTitle,
}: ChartDefinitionProps): Options => {
  return {
    caption: {
      style: {
        fontFamily: theme.fontFamily,
      },
    },
    chart: {
      type: 'column',
      renderTo: 'container',
      backgroundColor: '#FAFAFA',
      style: {
        fontFamily: theme.fontFamily,
      },
      animation: false,
    },
    xAxis: {
      ...getAdditionalXAxisConfig(categories),
      crosshair: true,
      labels: {
        style: {
          fontFamily: theme.fontFamily,
          fontSize: '11px',
          fontWeight: 'regular',
        },
      },
    },
    ...sharedOptions(),
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    yAxis: {
      min: 0,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontFamily: theme.fontFamily,
          fontSize: '11px',
          fontWeight: 'regular',
        },
      },
    },
    tooltip: {
      shape: 'rect',
      useHTML: true,
      borderWidth: 0,
      backgroundColor: '#fff',
      formatter: function () {
        return renderTooltipFormatter(
          this,
          this.key,
          !!pivotCriteria && this.series.name
        );
      },
    },
    legend: {
      enabled: !!pivotCriteria,
      itemStyle: {
        textOverflow: 'ellipsis',
        width: 200,
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    exporting: {
      filename: createCSVName(questionTitle),
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              padding: 0,
              allowOverlap: true,
              formatter: function () {
                if (!this.point.y) {
                  return null;
                }
                return `${this.point.y}`;
              },
            },
          },
        },
      },
    },
    series: series as Array<SeriesOptionsType>,
  };
};
