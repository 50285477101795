import * as React from 'react';
import Gleap from 'gleap';
import { useTranslation } from 'react-i18next';
import { PoweredByLogo } from 'Atoms/Icons/Commonplace/PoweredByLogo';
import { EmbedChartProps } from 'Client/pages/embedChart/types/types';
import { EmbedChartRenderer } from 'Client/pages/embedChart/EmbedChartRenderer/EmbedChartRenderer';
import {
  HTMLElementEvent,
  MouseEventCustom,
  checkLengthAndSetModal,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import {
  Container,
  FilterSummary,
  OptionsContainer,
  EmbedChartWrapper,
  Title,
  Modal,
} from './EmbedChartPreview.styles';
import { getChartsDataTypeOptions } from '../../utils';

export const EmbedChartPreview: React.FC<EmbedChartProps> = ({
  project,
  chartsProps,
  additionalOptions,
}: EmbedChartProps) => {
  const { t } = useTranslation();
  const [modal, setModal] = React.useState<boolean>(false);

  const {
    dataType,
    chartType,
    questionTitle,
    series,
    lineSeries,
    timeSeries,
    donutSeries,
    mapSeries,
    wordcloudData,
    months,
    pivotCriteria,
    categories,
    lineCategories,
  } = chartsProps;

  const { border, background, filters, logo, title } = additionalOptions;

  React.useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    };
  }, []);

  const chartsOptionsForType = getChartsDataTypeOptions({
    categories: categories || lineCategories,
    series: series || lineSeries,
    timeSeries: timeSeries || series,
    donutSeries: donutSeries,
    mapSeries: mapSeries,
    wordcloudData: wordcloudData,
    dataType: dataType,
    pivotCriteria: pivotCriteria,
    questionTitle: questionTitle,
    project,
    months: months,
  });

  const options = chartsOptionsForType[chartType];
  // need to pass bg color to inside highcharts options
  // without messing up other charts
  options.chart.backgroundColor = background ? '#fafafa' : '#ffffff';

  return (
    <Container>
      {!!title && (
        <Title
          onMouseOver={(
            e:
              | React.MouseEvent<HTMLDivElement, MouseEvent>
              | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
          ) => {
            setModal(() =>
              checkLengthAndSetModal(
                e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
              )
            );
          }}
          onMouseOut={() => setModal(false)}
        >
          <p>{title}</p>
          {modal && <Modal>{title}</Modal>}
        </Title>
      )}
      <EmbedChartWrapper border={border}>
        <EmbedChartRenderer
          options={options}
          dataType={dataType}
          chartType={chartType}
          questionTitle={questionTitle}
        />
      </EmbedChartWrapper>
      {(logo || filters.length > 0) && (
        <OptionsContainer>
          <FilterSummary>
            {filters.length > 0 && (
              <>
                <span>{t('Filters Applied to this chart:')}</span>
                <ul>
                  {filters.map((filter: string, index: number) => {
                    return <li key={`filter` + index}>{filter}</li>;
                  })}
                </ul>
              </>
            )}
          </FilterSummary>

          {logo && <PoweredByLogo />}
        </OptionsContainer>
      )}
    </Container>
  );
};
