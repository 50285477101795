/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useInView } from 'react-intersection-observer';
import { useProject } from 'Client/utils/hooks';
import { ResizeHorizontalIcon } from 'Atoms/Icons';
import {
  HTMLElementEvent,
  MouseEventCustom,
  checkLengthAndSetModal,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import {
  getNewPivotCriteria,
  mapSeriesDataToY,
  mapPivotedData,
  mapDemographicsOptions,
  mapChartsTypesOptions,
  mapDonutSeries,
  MAX_WIDTH,
  MIN_WIDTH,
  MIN_SIZE,
} from '../../utils';
import {
  ChartsFunctionsObject,
  ChartWrapperProps,
  MapSeries,
} from '../../types';
import { ChartHeader, ChartRenderer, NoDataDisplay, LoadingDisplay } from '../';
import { Wrapper, Title, ResizeContainer, Modal } from './ChartWrapper.styles';
import {
  GET_CHART_QUERY,
  GET_SENTIMENT_CHART_QUERY,
  GET_DEVICE_CHART_QUERY,
  GET_RESPONDENTS_CHART_QUERY,
  GET_SCD_CHART_QUERY,
} from './ChartWrapper.gql';
import { IframeModal } from '../IframeModal/IframeModal';
import { handleChartsVars } from '../../utils/handleChartsVars';

export const ChartWrapper: React.FC<ChartWrapperProps> = ({
  question,
  pivotData,
  appliedFilters,
  changeWidth,
  toggleMapSize,
  setChartTypes,
  chartTypes,
  setPivotCriteria,
  pivotCriteria,
  isMaxWidth,
  pageType,
  contributionsIds,
}: ChartWrapperProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const project = useProject();
  const { _id: projectId, id: projectName } = useProject();
  const { id, slug } = question;

  const demographicsOptions = mapDemographicsOptions(pivotData);

  const [isNoData, setIsNoData] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [series, setSeries] = React.useState([]);
  const [mapSeries, setMapSeries] = React.useState<MapSeries>();
  const [enableMapChart, setEnableMapChart] = React.useState();
  const [timeSeries, setTimeSeries] = React.useState([]);
  const [donutSeries, setDonutSeries] = React.useState([]);
  const [wordcloudData, setWordcloudData] = React.useState([]);
  const [months, setMonths] = React.useState([]);

  const [isIframeOpen, setIsIframeOpen] = React.useState<boolean>(false);
  const chartsTypesOptions =
    enableMapChart && project.features.mapChart
      ? mapChartsTypesOptions(question.type, true)
      : mapChartsTypesOptions(question.type);

  const { contributionsVars, respondentsVars, SCDVars } = handleChartsVars({
    data: question,
    pivotCriteria,
    appliedFilters,
    id,
    projectId,
    projectName,
  });

  const generateIframe = () => {
    setIsIframeOpen(true);
  };

  const [settingsFunctions, setSettingsFunctions] =
    React.useState<ChartsFunctionsObject>({
      viewInFullScreen: undefined,
      printChart: undefined,
      downloadPNG: undefined,
      downloadJPEG: undefined,
      downloadPDF: undefined,
      downloadSVG: undefined,
      downloadCSV: undefined,
      downloadXLS: undefined,
      generateIframe: generateIframe,
    });

  const queryDetails = (() => {
    switch (true) {
      case question.isSpecialCategory:
        return { query: GET_SCD_CHART_QUERY, input: SCDVars };
      case question.isRespondentsData || 'sensitiveType' in question.content:
        return { query: GET_RESPONDENTS_CHART_QUERY, input: respondentsVars };
      case pivotCriteria[id]?.type === 'device':
        return { query: GET_DEVICE_CHART_QUERY, input: contributionsVars };
      case question.type === 'sentiment':
        return { query: GET_SENTIMENT_CHART_QUERY, input: contributionsVars };
      default:
        return { query: GET_CHART_QUERY, input: contributionsVars };
    }
  })();

  const [getChart, { loading: gqlLoading, data: gqlChartData, refetch }] =
    useLazyQuery(queryDetails.query);

  React.useEffect(() => {
    if (inView) {
      getChart({
        variables: queryDetails.input,
      });
    }
  }, [inView]);

  const onPivotCriteriaUpdate = (pivotOptionValue: string): void => {
    const newPivotCriteria = getNewPivotCriteria({
      pivotData,
      pivotOptionValue,
    });
    const newPivotCriteriaObj = { ...pivotCriteria };
    newPivotCriteriaObj[id] = newPivotCriteria;
    setPivotCriteria(newPivotCriteriaObj);
    if (chartTypes[id] === 'wordcloud') {
      if (pivotOptionValue) {
        changeWidth(MAX_WIDTH);
      } else {
        changeWidth(MIN_WIDTH);
      }
    }
    refetch({
      ...queryDetails.input,
    });
  };

  const onChartTypeUpdate = (chartType: string) => {
    const newChartTypesObj = { ...chartTypes };
    newChartTypesObj[id] = chartType;
    setChartTypes(newChartTypesObj);
    if (
      chartType === 'line' ||
      chartType === 'bar' ||
      chartType === 'wordcloud'
    ) {
      changeWidth(MAX_WIDTH);
      return;
    } else {
      changeWidth(MIN_WIDTH);
    }

    if (chartType === 'map') {
      const newHeight = 1.1;

      toggleMapSize(newHeight, MAX_WIDTH);
    } else {
      // needs improvement can cause issues
      toggleMapSize(MIN_SIZE);
    }
  };

  const chartBalancer = () => {
    if (gqlLoading) return <LoadingDisplay />;
    if (isNoData) return <NoDataDisplay />;
    return (
      <ChartRenderer
        series={series}
        wordcloudData={wordcloudData}
        timeSeries={timeSeries}
        donutSeries={donutSeries}
        mapSeries={mapSeries}
        enableMapChart={enableMapChart}
        months={months}
        categories={categories}
        chartType={chartTypes[id]}
        dataType={question.type}
        questionTitle={question.label}
        questionId={question.id}
        setSettingsFunctions={setSettingsFunctions}
        pivotCriteria={pivotCriteria[id]?.category}
        mapSlug={slug}
        contributionsIds={contributionsIds}
      />
    );
  };

  React.useEffect(() => {
    if (!gqlChartData) return;
    const chartData = (() => {
      if (question.type === 'sentiment') {
        return gqlChartData.getSentimentChartData
          ? gqlChartData.getSentimentChartData.chartData
          : null;
      }
      if (question.isSpecialCategory) {
        return gqlChartData.getPseudoDemographicsChartData
          ? gqlChartData.getPseudoDemographicsChartData.chartData
          : null;
      }
      if (question.isRespondentsData || 'sensitiveType' in question.content) {
        return gqlChartData.getGaudiRespondentChartData
          ? gqlChartData.getGaudiRespondentChartData.chartData
          : null;
      }
      if (gqlChartData.getChartDeviceData) {
        return gqlChartData.getChartDeviceData
          ? gqlChartData.getChartDeviceData.chartData
          : null;
      }
      return gqlChartData.getChartData
        ? gqlChartData.getChartData.chartData
        : null;
    })();

    if (!chartData) {
      setIsNoData(true);
      return;
    }

    const {
      series,
      timeSeries,
      data: wordcloudData,
      wordSeries: pivotWordSeries,
      pivotCriteria: isPivoted,
      categories,
      months,
      drillDownSeries,
      mapSeries,
      enableMapChart,
      questionWithContent,
    } = chartData;

    if (mapSeries) {
      setMapSeries(mapSeries);
    }

    if (enableMapChart) {
      setEnableMapChart(enableMapChart);
    }

    if (isPivoted && !pivotWordSeries) {
      const mappedSeries = mapPivotedData({ series, categories });
      const donutSeries = mapDonutSeries(
        drillDownSeries?.pieSeries,
        isPivoted.category,
        question.type
      );
      setCategories(categories);
      setSeries(mappedSeries);
      setWordcloudData(pivotWordSeries);
      setDonutSeries(donutSeries);
      setIsNoData(false);
      return;
    }
    if (pivotWordSeries) {
      const test = JSON.parse(JSON.stringify(pivotWordSeries));
      setWordcloudData(test);
      setIsNoData(false);
      return;
    }
    if (chartTypes[id] === 'bar') {
      setSeries(series);
      setCategories(categories);
      setIsNoData(false);
      return;
    }

    if (chartTypes[id] === 'map') {
      setMapSeries(mapSeries);
      setEnableMapChart(enableMapChart);
      setIsNoData(false);
      return;
    }

    const content = questionWithContent?.questionContent?.content;
    const mappedSeries = content
      ? mapSeriesDataToY(series || [], {
          currency: content.unit,
        })
      : mapSeriesDataToY(series || []);
    setSeries(mappedSeries);
    setCategories(categories);
    setWordcloudData(wordcloudData);
    setTimeSeries(timeSeries);
    setMonths(months);
    setIsNoData(false);
  }, [gqlChartData, gqlLoading]);

  return (
    <div ref={ref}>
      <Title
        onMouseOver={(
          e:
            | React.MouseEvent<HTMLDivElement, MouseEvent>
            | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
        ) => {
          setModal(() =>
            checkLengthAndSetModal(
              e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
            )
          );
        }}
        onMouseOut={() => setModal(false)}
      >
        <p>{question.label}</p>
        {modal && <Modal>{question.label}</Modal>}
      </Title>

      <Wrapper isLoading={gqlLoading} data-testid="ChartWrapper-Wrapper">
        {(chartTypes[id] === 'line' ||
          chartTypes[id] === 'bar' ||
          chartTypes[id] === 'wordcloud') &&
          !gqlLoading && (
            <ResizeContainer
              isMaxWidth={isMaxWidth}
              onClick={() => changeWidth()}
            >
              <ResizeHorizontalIcon />
            </ResizeContainer>
          )}
        {!gqlLoading && (
          <ChartHeader
            pageType={pageType}
            id={question.id}
            chartTypes={chartTypes}
            dataType={question.type}
            chartTitle={question.label}
            demographicsOptions={demographicsOptions}
            chartsTypesOptions={chartsTypesOptions}
            onPivotCriteriaUpdate={onPivotCriteriaUpdate}
            pivotCriteria={pivotCriteria[id]}
            onChartTypeUpdate={onChartTypeUpdate}
            settingsFunctions={settingsFunctions}
            isNoData={isNoData}
          />
        )}
        {chartBalancer()}

        {isIframeOpen && (
          <IframeModal
            setIsIframeOpen={setIsIframeOpen}
            isIframeOpen={isIframeOpen}
            data={question}
            chartTypes={chartTypes}
            pivotCriteria={pivotCriteria}
            projectId={projectId}
            id={id}
            appliedFilters={appliedFilters}
            projectName={projectName}
          ></IframeModal>
        )}
      </Wrapper>
    </div>
  );
};
