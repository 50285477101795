import * as React from 'react';
import Select from 'react-select';
import { OptionItem } from 'Client/types';
import { SettingsIcon } from 'Atoms/Icons';
import { ChartHeaderProps } from '../../types';
import {
  Wrapper,
  SelectContainer,
  SelectWrapper,
  Label,
  IconsContainer,
} from './ChartHeader.styles';
import { SettingsMenu } from '../SettingsMenu';

export const ChartHeader: React.FC<ChartHeaderProps> = ({
  demographicsOptions,
  chartsTypesOptions,
  onPivotCriteriaUpdate,
  onChartTypeUpdate,
  settingsFunctions,
  chartTypes,
  id,
  pivotCriteria,
  dataType,
  pageType,
}: ChartHeaderProps) => {
  const [openSettingsMenu, setOpenSettingsMenu] =
    React.useState<boolean>(false);
  const onPivotChange = (option: OptionItem): void => {
    if (!option) {
      onPivotCriteriaUpdate(option as null);
      return;
    }
    const { value } = option;
    onPivotCriteriaUpdate(value as string);
  };

  const onChartTypeChange = (option: OptionItem): void => {
    const { value } = option;
    onChartTypeUpdate(value as string);
  };

  const pivotSelectCheck = () => {
    if (
      chartTypes[id] !== 'line' &&
      dataType !== 'matrix' &&
      dataType !== 'priorities' &&
      pageType !== 'respondents'
    ) {
      return true;
    }

    return false;
  };

  return (
    <Wrapper>
      <SelectWrapper>
        {pivotSelectCheck() && (
          <SelectContainer data-onboarding="dashboard-pivot-chart">
            <Label>Pivot criteria:</Label>
            <Select
              classNamePrefix="react-select"
              placeholder=""
              defaultValue={
                {
                  value: pivotCriteria?.category,
                  label: pivotCriteria?.category,
                } as OptionItem
              }
              options={demographicsOptions}
              isClearable={!!pivotCriteria}
              onChange={onPivotChange}
            />
          </SelectContainer>
        )}
        <SelectContainer data-onboarding="dashboard-change-chart-type">
          <Label>Chart type:</Label>
          <Select
            classNamePrefix="react-select"
            options={chartsTypesOptions?.options}
            defaultValue={
              {
                value: chartTypes[id],
                label: chartTypes[id],
              } as OptionItem
            }
            isClearable={false}
            onChange={onChartTypeChange}
          />
        </SelectContainer>
      </SelectWrapper>
      <IconsContainer>
        <div onClick={() => setOpenSettingsMenu((previous) => !previous)}>
          <SettingsIcon data-testid="charts-settings-icon" />
        </div>
      </IconsContainer>
      {openSettingsMenu && (
        <SettingsMenu settingsFunctions={settingsFunctions} />
      )}
    </Wrapper>
  );
};
