import { Options, SeriesOptionsType } from 'highcharts';
import { theme } from 'Client/components/theme';
import { ChartDefinitionProps } from '../types';
import { sharedOptions } from '.';
import { createCSVName, renderTooltipFormatter } from './utils';

export const generateBarChartOptions = ({
  series,
  categories,
  questionTitle,
}: ChartDefinitionProps): Options => {
  return {
    caption: {
      style: {
        fontFamily: theme.fontFamily,
      },
    },
    chart: {
      type: 'bar',
      renderTo: 'container',
      backgroundColor: '#FAFAFA',
      style: {
        fontFamily: theme.fontFamily,
      },
      animation: false,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      labels: {
        style: {
          fontFamily: theme.fontFamily,
          fontSize: '11px',
          fontWeight: 'regular',
        },
      },
    },
    ...sharedOptions(),
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    yAxis: {
      min: 0,
      endOnTick: false,
      maxPadding: 0,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontFamily: theme.fontFamily,
          fontSize: '11px',
          fontWeight: 'regular',
        },
      },
    },
    tooltip: {
      shape: 'rect',
      useHTML: true,
      borderWidth: 0,
      backgroundColor: '#fff',
      formatter: function () {
        return renderTooltipFormatter(this, this.series.name, '');
      },
    },
    legend: {
      reversed: true,
      itemStyle: {
        textOverflow: 'ellipsis',
        width: 200,
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        animation: false,
      },
    },
    exporting: {
      filename: createCSVName(questionTitle),
    },
    series: [...series].sort(
      (a, b) => Number(a.data[0] || 0) - Number(b.data[0] || 0)
    ) as Array<SeriesOptionsType>,
  };
};
