import * as React from 'react';
import { AddProposalBoldIcon } from 'Icons';
import { Wrapper } from './SectionTitle.styles';
import { SectionTitleProps } from './types';

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  isProposal,
}: SectionTitleProps) => {
  if (!title) return <></>;
  return (
    <Wrapper data-testid="SectionTitle-Wrapper">
      {isProposal ? (
        <>
          <AddProposalBoldIcon data-testid="SectionTitle-AddProposalBoldIcon" />
          <h1>{title}</h1>
        </>
      ) : (
        <>
          <h1 data-onboarding="dashboard-special-Category-Demographics">
            {title}
          </h1>
        </>
      )}
    </Wrapper>
  );
};
