import { format } from 'date-fns';

export const createCSVName = (questionName) => {
  const date = format(new Date(), 'ddMMyyyy');
  // remove numbers and special characthers from the beginning of the string
  const stringCleanUp = questionName.replace(/^[\d\W.]{1,}|[.]/g, '');
  const rmSpaces = stringCleanUp.replace(/\s/g, '_');

  if (rmSpaces.length <= 100) {
    return `${rmSpaces}_${date}`;
  }

  return `${rmSpaces.substring(0, 99)}+${date}`;
};
