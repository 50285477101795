import { Options, SeriesOptionsType } from 'highcharts';
import { theme } from 'Client/components/theme';
import { ChartDefinitionProps } from '../types';
import { sharedOptions } from '.';
import { createCSVName, renderTooltipFormatter } from './utils';

export const generatePackedBubbleChartOptions = ({
  wordcloudData,
  questionTitle,
}: ChartDefinitionProps): Options => {
  return {
    ...sharedOptions(),
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'packedbubble',
      renderTo: 'container',
      backgroundColor: '#FAFAFA',
      style: {
        fontFamily: theme.fontFamily,
      },
      animation: false,
    },
    legend: {
      enabled: true,
      itemStyle: {
        textOverflow: 'ellipsis',
        width: 200,
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
      packedbubble: {
        minSize: '20%',
        maxSize: '100%',
        allowPointSelect: true,
        cursor: 'pointer',
        borderWidth: 3,
        borderColor: '#fff',
        draggable: false,
        className: 'packedBubble',
        layoutAlgorithm: {
          gravitationalConstant: 0.05,
          splitSeries: true,
          seriesInteraction: false,
          dragBetweenSeries: true,
          parentNodeLimit: true,
          enableSimulation: false,
        },
        dataLabels: {
          enabled: true,
          parentNodeFormat: undefined,
          format: '{point.name}',
          style: {
            fontFamily: theme.fontFamily,
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      },
    },
    tooltip: {
      shape: 'rect',
      useHTML: true,
      borderWidth: 0,
      backgroundColor: '#fff',
      formatter: function () {
        return renderTooltipFormatter(this, this.key, this.series.name);
      },
    },
    exporting: {
      filename: createCSVName(questionTitle),
    },
    series: wordcloudData as unknown as Array<SeriesOptionsType>,
  };
};
