import { Options, SeriesOptionsType } from 'highcharts';
import { ChartDefinitionProps } from '../types';
import { sharedOptions } from '.';
import { renderTooltipFormatter, createCSVName } from './utils';

export const generateVisitorsLineChartOptions = ({
  timeSeries,
  series,
  categories,
  questionTitle,
}: ChartDefinitionProps): Options => {
  const chartSeries = timeSeries[0] ? timeSeries : series;
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'line',
      renderTo: 'container',
      backgroundColor: '#FFFFFF',
      style: {
        fontFamily: 'Helvetica',
      },
      animation: false,
    },
    plotOptions: {
      series: {
        turboThreshold: 5000,
        animation: false,
        lineWidth: 4,
        marker: {
          symbol: 'circle',
          enabled: false,
          lineWidth: 4,
          lineColor: null,
        },
      },
    },
    xAxis: {
      type: 'category',
      categories: [...new Set(categories)],
      labels: {
        style: {
          fontFamily: 'Helvetica',
          fontSize: '14px',
          fontWeight: 'regular',
        },
      },
    },
    yAxis: {
      min: 0,
      labels: {
        style: {
          fontFamily: 'Helvetica',
          fontSize: '14px',
          fontWeight: 'regular',
        },
      },
    },
    ...sharedOptions(),
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    tooltip: {
      shape: 'rect',
      useHTML: true,
      borderWidth: 0,
      backgroundColor: '#fff',
      formatter: function () {
        return renderTooltipFormatter(
          this,
          this.series.name,
          '',
          undefined,
          'line'
        );
      },
    },
    series: chartSeries as Array<SeriesOptionsType>,
    exporting: {
      filename: createCSVName(questionTitle),
    },
    legend: {
      enabled: true,
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 10,
      symbolPadding: 10,
      itemStyle: {
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: 'regular',
      },
    },
    boost: {
      enabled: true,
      seriesThreshold: 1,
    },
  };
};
