import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsData from 'highcharts/modules/data';
import HC_more from 'highcharts/highcharts-more';
import wordCloud from 'highcharts/modules/wordcloud';
import mapCharts from 'highcharts/modules/map';
import accessibility from 'highcharts/modules/accessibility';
import mapCluster from 'highcharts/modules/marker-clusters';
import { Wrapper } from './EmbedChartRenderer.styles';
import { EmbedChartRendererProps } from '../types/types';

export const EmbedChartRenderer: React.FC<EmbedChartRendererProps> = ({
  dataType,
  chartType,
  questionTitle,
  options,
}: EmbedChartRendererProps) => {
  if (typeof Highcharts === 'object') {
    wordCloud(Highcharts);
    mapCharts(Highcharts);
    mapCluster(Highcharts);
    accessibility(Highcharts);
    HighchartsData(Highcharts);
    HC_more(Highcharts);
  }

  const chart = React.useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);

  return (
    <>
      <Wrapper id="container">
        <HighchartsReact
          key={`${dataType}-${questionTitle}-${chartType}`}
          highcharts={Highcharts}
          constructorType={chartType === 'map' ? 'mapChart' : 'chart'}
          options={options}
          allowChartUpdate={true}
          immutable={true}
          ref={chart}
        />
      </Wrapper>
    </>
  );
};
