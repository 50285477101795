import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colorMappings.chartBackground};
  width: inherit;
  height: inherit;
  display: grid;

  :first-child {
    border-radius: 0.5rem;
  }
  div:first-child {
    border-radius: 0.5rem;
  }
`;
