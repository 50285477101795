import { Options } from 'highcharts';

export const sharedOptions = (): Options => {
  return {
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    colors: [
      '#1B365D',
      '#E56DB1',
      '#D3273E',
      '#FF802E',
      '#00BFB2',
      '#7C7C7C',
      '#41B6E6',
      '#6841B9',
    ],
    title: {
      text: undefined,
    },
    yAxis: {
      min: 0,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontFamily: 'Helvetica',
        },
      },
    },
    legend: {
      enabled: false,
    },
  };
};
