export const MAX_SIZE = 2;
export const MIN_SIZE = 1;

interface IToggleMapSize {
  id: string;
  layout: Array<{ [key: string]: number | string }>;
  newSIZE?: { h: number; w: number };
}

export const toggleMapSize = ({ id, layout, newSIZE }: IToggleMapSize) => {
  const newLayout = layout.map((q) => {
    const newQ = { ...q };
    if (q.i === id) {
      switch (true) {
        case !!newSIZE:
          newQ.h = newSIZE.h;
          newQ.w = newSIZE.w;
          break;
        case q.h === MIN_SIZE:
          newQ.h = MAX_SIZE;
          newQ.w = MAX_SIZE;
          break;
        case q.h === MAX_SIZE:
          newQ.h = MIN_SIZE;
          newQ.w = MIN_SIZE;
          break;
        default:
          newQ.h = MIN_SIZE;
          newQ.w = MIN_SIZE;
      }
    }
    return newQ;
  });
  return newLayout;
};
