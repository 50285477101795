export const getDonutInnerSeriesNames = (questionType: string) => {
  switch (questionType) {
    case 'rating-number':
      return 'Rating';
    case 'smilies' || 'sentiment':
      return 'Sentiments';
    default:
      return 'Sentiments';
  }
};
