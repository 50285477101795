import { ChartSeries } from 'Shared/types/dashboard';
import { ChartOptions, SeriesType } from '../types';

export const mapSeriesDataToY = (
  series: Array<ChartSeries>,
  options?: ChartOptions
): SeriesType => {
  const mappedToYSeries = series.map((seriesData, index) => {
    const { data, name, type, color } = seriesData;
    return {
      name,
      type,
      color,
      index,
      stacking: 'normal',
      y: data[0] as number,
      currency: options?.currency || null,
    };
  }) as Array<ChartSeries>;
  return [
    {
      data: mappedToYSeries,
    },
  ];
};
