import * as React from 'react';
import { IconProps } from '../types';

export const CopySmallIcon: React.FC<IconProps> = ({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  color = 'white',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.54047 3.12801C6.54047 2.05737 7.4084 1.18945 8.47903 1.18945H12.8564C13.9271 1.18945 14.795 2.05737 14.795 3.12801V7.5054C14.795 8.57603 13.9271 9.44396 12.8564 9.44396H11.9184C11.5385 9.44396 11.2305 9.13598 11.2305 8.75608C11.2305 8.37618 11.5385 8.06821 11.9184 8.06821H12.8564C13.1672 8.06821 13.4192 7.81623 13.4192 7.5054V3.12801C13.4192 2.81718 13.1672 2.5652 12.8564 2.5652H8.47903C8.1682 2.5652 7.91622 2.81718 7.91622 3.12801V4.06602C7.91622 4.44593 7.60825 4.7539 7.22835 4.7539C6.84845 4.7539 6.54047 4.44593 6.54047 4.06602V3.12801ZM2.16309 7.5054C2.16309 6.43476 3.03101 5.56684 4.10164 5.56684H8.47903C9.54967 5.56684 10.4176 6.43476 10.4176 7.5054V11.8828C10.4176 12.9534 9.54967 13.8213 8.47903 13.8213H4.10164C3.03101 13.8213 2.16309 12.9534 2.16309 11.8828V7.5054ZM4.10164 6.94259C3.79081 6.94259 3.53884 7.19457 3.53884 7.5054V11.8828C3.53884 12.1936 3.79081 12.4456 4.10164 12.4456H8.47903C8.78986 12.4456 9.04184 12.1936 9.04184 11.8828V7.5054C9.04184 7.19457 8.78986 6.94259 8.47903 6.94259H4.10164Z"
      fill={color}
    />
  </svg>
);
