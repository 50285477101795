import { Options, SeriesOptionsType } from 'highcharts';
import { theme } from 'Client/components/theme';
import { ChartDefinitionProps } from '../types';
import { sharedOptions } from '.';
import { createCSVName, renderTooltipFormatter } from './utils';

export const generatePieChartOptions = ({
  series,
  donutSeries,
  pivotCriteria,
  questionTitle,
}: ChartDefinitionProps): Options => {
  let newSeries = [...series] as any;
  const removeZero =
    newSeries[0] &&
    newSeries[0].data
      .map((s) => {
        if (!s.y || s.y === 0 || isNaN(s.y)) {
          return;
        }
        return s;
      })
      .filter(Boolean);
  newSeries = [{ data: removeZero }];

  const seriesToRender = pivotCriteria ? donutSeries : newSeries;
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      renderTo: 'container',
      backgroundColor: '#FAFAFA',
      style: {
        fontFamily: theme.fontFamily,
      },
      animation: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        borderWidth: 3,
        borderColor: '#fff',
        shadow: true,
        dataLabels: {
          style: {
            fontFamily: theme.fontFamily,
            fontSize: '14px',
            fontWeight: 'regular',
          },
        },
      },
    },
    tooltip: {
      shape: 'rect',
      useHTML: true,
      borderWidth: 0,
      backgroundColor: '#fff',
      formatter: function () {
        return renderTooltipFormatter(
          this,
          this.key,
          !!pivotCriteria && this.series.name
        );
      },
    },
    ...sharedOptions(),
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    series: seriesToRender as Array<SeriesOptionsType>,
    exporting: {
      filename: createCSVName(questionTitle),
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              formatter: function () {
                if (!this.point.y) {
                  return null;
                }
                return `${this.point.name}: ${this.point.y}`;
              },
            },
          },
        },
      },
    },
  };
};
