import styled from 'styled-components';
import { DownloadIcon } from 'Components/atoms/Icons';

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 99;
  border: 0.05rem solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.1875rem;
  top: 2.5rem;
  right: 1rem;
`;

export const ButtonsContainer = styled.div<{ lastContainer?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.8125rem 0;
  border-bottom: ${({ lastContainer }) =>
    !lastContainer && `0.0625rem solid rgba(0, 168, 90, 0.15)`};
`;
export const Button = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.3125rem 1.1875rem 0;
  font-size: 0.875rem;
  line-height: 1.125rem;
  cursor: pointer;

  svg {
    width: 0.9rem;
    margin-right: 0.5625rem;
    stroke-width: 0.0625rem;
    filter: invert(1);
    color: white;
  }
`;

export const StyledDownloadIcon = styled(DownloadIcon).attrs({
  strokeWidth: 1,
})``;

export const NewIcon = styled.div`
  margin-right: 0.25rem;
  border-radius: 0.25rem;
  padding: 0.125rem;
  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  background: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
`;
