import { QuestionConfig } from '../types';

export const chartsTypes = {
  COLUMN: 'column',
  LINE: 'line',
  VISITORSLINE: 'visitorsLine',
  PIE: 'pie',
  WORDCLOUD: 'wordcloud',
  BAR: 'bar',
};
const { COLUMN, LINE, PIE, WORDCLOUD, VISITORSLINE, BAR } = chartsTypes;

export const text = {
  questionType: 'text',
  defaultChartType: WORDCLOUD,
  possibleChartTypes: [WORDCLOUD],
};

export const textarea = {
  questionType: 'textarea',
  defaultChartType: WORDCLOUD,
  possibleChartTypes: [WORDCLOUD],
};

export const sentiment = {
  questionType: 'sentiment',
  defaultChartType: PIE,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const status = {
  questionType: 'status',
  defaultChartType: PIE,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const select = {
  questionType: 'select',
  defaultChartType: COLUMN,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const smilie = {
  questionType: 'smilie',
  defaultChartType: PIE,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const rating = {
  questionType: 'rating-number',
  defaultChartType: PIE,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const imagepoll = {
  questionType: 'imagepoll',
  defaultChartType: COLUMN,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const textpoll = {
  questionType: 'textpoll',
  defaultChartType: COLUMN,
  possibleChartTypes: [COLUMN, LINE, PIE],
};
export const label = {
  questionType: 'label',
  defaultChartType: COLUMN,
  possibleChartTypes: [COLUMN, LINE, PIE],
};

export const priorities = {
  questionType: 'priorities',
  defaultChartType: BAR,
  possibleChartTypes: [BAR],
};

export const matrix = {
  questionType: 'matrix',
  defaultChartType: BAR,
  possibleChartTypes: [BAR],
};

export const budget = {
  questionType: 'budget',
  defaultChartType: COLUMN,
  possibleChartTypes: [COLUMN],
};

export const visitors = {
  questionType: 'visitors',
  defaultChartType: VISITORSLINE,
  possibleChartTypes: [VISITORSLINE],
};
export const overview = {
  questionType: 'overview',
  defaultChartType: VISITORSLINE,
  possibleChartTypes: [VISITORSLINE],
};

export const specialCategoryData = {
  questionType: 'special-category-data',
  defaultChartType: PIE,
  possibleChartTypes: [PIE],
};

export const questionsToChartsType: Array<QuestionConfig> = [
  sentiment,
  status,
  select,
  smilie,
  rating,
  imagepoll,
  textpoll,
  label,
  text,
  textarea,
  priorities,
  matrix,
  budget,
  visitors,
  overview,
  specialCategoryData,
];
